import React, { useRef } from "react";
import Slider from "react-slick";

import Img from "../assets/jpg/img.jpg";
import Img2 from "../assets/jpg/img2.jpg";
import Img3 from "../assets/jpg/img3.jpg";
import Img4 from "../assets/jpg/img4.jpg";
import Img5 from "../assets/jpg/img5.jpg";
import Img6 from "../assets/jpg/img6.jpg";
import Img7 from "../assets/jpg/img7.jpg";
import Img8 from "../assets/jpg/img8.jpg";
import Img9 from "../assets/jpg/img9.jpg";
import Img10 from "../assets/jpg/img10.jpg";
import Img11 from "../assets/jpg/img11.jpg";
import Left from "../assets/svg/left.svg";
import Right from "../assets/svg/right.svg";

// Array of images
const images = [
  { id: 11, src: Img11, title: "Men’s hair cut" },

  { id: 10, src: Img10, title: " All kinds of fades" },
  { id: 9, src: Img9, title: " All kinds of fades" },

  { id: 7, src: Img7, title: "Kids Haircuts" },
  { id: 8, src: Img8, title: "Shave" },
  { id: 6, src: Img6, title: "All Kind Of Fade" },
  { id: 5, src: Img5, title: "Hair wash" },
  { id: 3, src: Img3, title: "Ladies Hair cuts" },
  { id: 2, src: Img2, title: "Threading" },
  { id: 4, src: Img4, title: "Hair Colour" },
  { id: 1, src: Img, title: "Facial" },


];

export default function CustomSlider() {
  const sliderRef = useRef(null);

  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    speed: 1000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots:false,
        },
      },
    ],
  };

  const handleSlideNext = () => {
    console.log("Next");
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  };

  const handleSlidePrev = () => {
    console.log("Prev");
    if (sliderRef.current) {
      sliderRef.current.slickPrev();
    }
  };

  return (
    <>
      <div id="service" className="bg-[#A22F72] pt-10 pb-10 md:pb-28 relative">
        <p className="font-[700] text-[30px] md:text-[40px] 2xl:text-[50px] text-white text-center">
          SERVICES
        </p>

        <div className=" w-[80%] 2xl:w-[50%] mx-auto mt-20">
          <Slider ref={sliderRef} {...settings}>
            {images.map((image) => (
              <div key={image.id}>
                <div className="w-[90%] mx-auto">
                  <img
                    src={image.src}
                    alt=""
                    className="h-[300px] lg:h-[450px] w-full rounded-[10px]"
                  />
                  <p className="font-[600] text-[20px] text-white mt-5 text-center">
                    {image.title}
                  </p>
                </div>
              </div>
            ))}
          </Slider>
        </div>
        <div className="absolute top-[45%] left-0 right-0 md:w-[90%] 2xl:w-[60%] mx-auto flex justify-between">
          <img
            onClick={handleSlidePrev}
            src={Left}
            alt=""
            className="cursor-pointer w-[10%] md:w-[8%] lg:w-[7%] xl:w-auto"
          />
          <img
            onClick={handleSlideNext}
            src={Right}
            alt=""
            className="cursor-pointer w-[10%] md:w-[8%] lg:w-[7%] xl:w-auto"
          />
        </div>
      </div>
    </>
  );
}
