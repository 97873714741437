import React from "react";
import Banner from "../assets/png/banner.png";
import "@fontsource/inter";
import Navbar from "../component/navbar";
import Slider from "../component/slider";
import Contact from "../component/contact";
import Footer from "../component/footer";

export default function Landing() {
  return (
    <>
      <div id="home" className="relative">
        <img src={Banner} alt="" />
        <div className="absolute top-0 w-full">
          <Navbar />
        </div>
      </div>

      <div
        id="about"
        className="pt-10 pb-20 w-[90%] xl:w-[80%] 2xl:w-[60%] mx-auto"
      >
        <p className="font-[700] text-[30px] md:text-[40px] 2xl:text-[50px] text-[#EBAA8A] text-center">
          ABOUT US
        </p>

        <div className="grid md:grid-cols-2 gap-10 md:gap-20 mt-5 md:mt-10">
          <div>
            <p className="font-[400] text-[14px] md:text-[16px]  2xl:text-[20px] text-black text-justify">
              Welcome to Ynnovacuts and Spa Studio, where passion meets
              precision in every cut. With a decade of experience in the hair
              industry, Poonam has honed her skills in various stores and
              salons, gaining invaluable expertise along the way. Driven by a
              desire to create a space that blends innovation with tradition,
              Poonam established her own sanctuary of style and sophistication.
              At Ynnovacuts and Spa Studio, every haircut is more than just a
              service—it's an opportunity to empower, inspire, and leave a
              lasting impression.
            </p>
          </div>
          <div>
            <p className="font-[400] text-[14px] md:text-[16px]  2xl:text-[20px] text-black text-justify">
              Beyond the scissors and clippers, our studio is a testament to
              perseverance and the pursuit of dreams. Whether you seek a classic
              cut or a bold new style, trust Ynnovacuts and Spa Studio to exceed
              your expectations and leave you looking and feeling your best.
              Experience the difference passion makes at Ynnovacuts and Spa
              Studio
            </p>
          </div>
        </div>
      </div>
      <Slider />
      <Contact />
      <Footer />
    </>
  );
}
