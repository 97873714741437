import React, { Fragment, useState } from "react";
import Logo from "../assets/svg/logo.svg";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { RiMenu3Fill } from "react-icons/ri";
import AnchorLink from "react-anchor-link-smooth-scroll";

export default function Navbar() {
  const [open, setOpen] = useState(false);

  return (
    <>
      <div className="grid grid-cols-2 w-[90%] xl:w-[80%] 2xl:w-[60%] mx-auto">
        <div className="col-span-1 ">
          <img
            src={Logo}
            alt=""
            className="pt-3 md:pt-6 w-[80%] md:w-[50%] lg:w-auto"
          />
        </div>
        <div className="col-span-2 hidden md:flex justify-end gap-10 -mt-4 lg:-mt-7">
          <AnchorLink href="#home">
            <p className="font-[700] text-[14px] lg:text-[20px] text-white cursor-pointer">
              HOME
            </p>
          </AnchorLink>
          <AnchorLink href="#about">
            <p className="font-[700] text-[14px] lg:text-[20px] text-white cursor-pointer">
              ABOUT
            </p>
          </AnchorLink>

          <AnchorLink href="#service">
            <p className="font-[700] text-[14px] lg:text-[20px] text-white cursor-pointer">
              SERVICES
            </p>
          </AnchorLink>
          <AnchorLink href="#contact">
            <p className="font-[700] text-[14px] lg:text-[20px] text-white cursor-pointer">
              CONTACT
            </p>
          </AnchorLink>
        </div>
        <div className="block md:hidden pt-3 ml-auto">
          <RiMenu3Fill
            onClick={() => setOpen(true)}
            className="text-[20px] text-white cursor-pointer"
          />
        </div>
      </div>

      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative " onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <div className="fixed inset-0 overflow-hidden  z-10">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-0">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto relative w-screen max-w-full">
                    <Transition.Child
                      as={Fragment}
                      enter="ease-in-out duration-500"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="ease-in-out duration-500"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <div className="absolute top-0 left-0 -ml-4 flex pt-4 pr-2 sm:-ml-10 sm:pr-4">
                        {/* <button
                          type="button"
                          className="rounded-md text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                          onClick={() => setOpen(false)}
                        >
                          <span className="sr-only">Close panel</span>
                          <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                        </button> */}
                      </div>
                    </Transition.Child>
                    <div className="flex w-[100%] ml-auto h-full flex-col overflow-y-scroll  bg-[#A22F72] shadow-xl relative z-40">
                      <div className=" container grid grid-cols-1 py-1 ">
                        <div className="flex justify-between px-5 border-b-[1px] border-[#D9D9D94D] py-2 ">
                          <img src={Logo} alt="" className="w-[40%]" />

                          <div className="w-[50%] px-3"></div>
                          <button
                            type="button"
                            className=" rounded-md text-white  focus:outline-none"
                            onClick={() => setOpen(false)}
                          >
                            <span className="sr-only">Close panel</span>
                            <XMarkIcon className="h-8 w-8" aria-hidden="true" />
                          </button>
                        </div>

                        <div className=" p-5">
                          <AnchorLink
                            onClick={() => setOpen(false)}
                            href="#home"
                          >
                            <p className="mt-5 font-[700] text-[14px] lg:text-[20px] text-white cursor-pointer">
                              HOME
                            </p>
                          </AnchorLink>
                          <AnchorLink
                            onClick={() => setOpen(false)}
                            href="#about"
                          >
                            <p className="mt-5 font-[700] text-[14px] lg:text-[20px] text-white cursor-pointer">
                              ABOUT
                            </p>
                          </AnchorLink>
                          <AnchorLink
                            onClick={() => setOpen(false)}
                            href="#service"
                          >
                            <p className="mt-5 font-[700] text-[14px] lg:text-[20px] text-white cursor-pointer">
                              SERVICES
                            </p>
                          </AnchorLink>
                          <AnchorLink
                            onClick={() => setOpen(false)}
                            href="#contact"
                          >
                            <p className="mt-5 font-[700] text-[14px] lg:text-[20px] text-white cursor-pointer">
                              CONTACT
                            </p>
                          </AnchorLink>
                        </div>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
