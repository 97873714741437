import React from "react";
import Icon from "../assets/svg/icon.svg";
import Icon2 from "../assets/svg/icon2.svg";
import Icon3 from "../assets/svg/icon3.svg";
import { Link } from "react-router-dom";
import AnchorLink from "react-anchor-link-smooth-scroll";

export default function Footer() {
  return (
    <>
      <div className="bg-[#EBAA8A] pt-20 pb-24">
        <div className="grid md:grid-cols-2 w-[90%] xl:w-[80%] 2xl:w-[60%] mx-auto mt-10 md:mt-16">
          <div>
            <p className="font-[700] text-[25px] md:text-[35px] text-black">
              Social Media
            </p>
            <div className="flex gap-5 md:gap-8  mt-5 md:mt-10">
              <img
                src={Icon}
                alt=""
                className="cursor-pointer w-[10%] lg:w-[8%] xl:w-[5%]"
              />
              <Link
                className="cursor-pointer w-[10%] lg:w-[8%] xl:w-[5%]"
                to="https://www.instagram.com/freshfades.ca?igsh=bG42Z2ZnejNocHVn&utm_source=qr"
                target="_blank"
              >
                <img src={Icon2} alt="" />
              </Link>
              {/* <img
                src={Icon3}
                alt=""
                className="cursor-pointer w-[10%] lg:w-[8%] xl:w-[5%]"
              /> */}
            </div>

            <AnchorLink href="#home">
              <button className="font-[700] text-[10px] md:text-[20px] text-white bg-black py-[10px] px-24 mt-5 md:mt-10 hover:shadow-md">
                GO ON TOP
              </button>
            </AnchorLink>
          </div>

          <div className="md:ml-auto lg:ml-0 mt-10 md:mt-0">
            <p className="font-[700] text-[25px] md:text-[35px] text-black">
              Where Are We
            </p>
            <p className="mt-5 md:mt-10 font-[400] text-[14px] md:text-[18px] text-black">
              2459 Kelly Circle SW
            </p>
            <p className="mt-1 font-[400] text-[14px] md:text-[18px] text-black">
              EDMONTON ABB
            </p>
            <p className="mt-1 font-[400] text-[14px] md:text-[18px] text-black">
              T6W4G2
            </p>
            <p className="mt-5 font-[400] text-[14px] md:text-[18px] text-black">
              +18257779555
            </p>
            <p className="mt-1 font-[400] text-[14px] md:text-[18px] text-black">
              +17809098673
            </p>
          </div>
        </div>
      </div>

      <div className="bg-black">
        <div className="flex justify-between w-[90%] xl:w-[80%] 2xl:w-[60%] mx-auto py-6">
          <Link to="https://thedigiauthor.com/" target="_blank">
            <p className="font-[400] text-[14px] md:text-[18px] text-white cursor-pointer">
              Designed By The Digi Author
            </p>
          </Link>
          <p className="font-[400] text-[14px] md:text-[18px] text-white">
            copyright @ 2024
          </p>
        </div>
      </div>
    </>
  );
}
