import React from "react";
import Img from "../assets/jpg/contact.jpg";

export default function Contact() {
  return (
    <>
      <div id="contact" className="relative">
        <img src={Img} alt="" className="w-full" />
        <div className="bg-white border-[1px] border-black w-[80%] md:w-[70%] lg:w-[50%] xl:w-[40%] mx-auto text-center py-5 md:py-20 absolute -bottom-16 left-0 right-0">
          <p className="font-[800] text-[15px] md:text-[35px] text-black">
            Call Us Today To{" "}
          </p>
          <p className="font-[800] text-[15px] md:text-[35px] text-black">
            Book Your Appoinment !{" "}
          </p>

          <a href="tel:+17809098673">
            <button className="font-[700] text-[10px] md:text-[20px] text-white bg-black py-[10px] w-[40%] mt-5 md:mt-10 hover:shadow-md">
              CALL NOW
            </button>
          </a>
        </div>
      </div>
    </>
  );
}
